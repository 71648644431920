<template>
  <div>
    <div id="simple-calendar-app">
      <div class="vx-card app-fixed-height">
        <calendar-view
          ref="calendar"
          :show-date="showDate"
          :events="simpleCalendarEvents"
          @click-event="openEditEvent"
          @click-date="onClickDay"
          :eventTop="windowWidth <= 400 ? '2rem' : '2rem'"
          
          eventBorderHeight="0px"
          class="theme-default"
        >
          <div slot="header" class="mb-4">
            <div class="vx-row no-gutter">
              <!-- Month Name -->

              <!-- Current Month -->
              <div
                class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex justify-start order-start"
              >
                <div class="flex items-center">
                  <feather-icon
                    icon="ChevronLeftIcon"
                    @click="updateMonth(-1)"
                    svgClasses="w-5 h-5 m-1"
                    class="cursor-pointer bg-primary text-white rounded-full"
                  />
                  <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                    showDate | month
                  }}</span>
                  <feather-icon
                    icon="ChevronRightIcon"
                    @click="updateMonth(1)"
                    svgClasses="w-5 h-5 m-1"
                    class="cursor-pointer bg-primary text-white rounded-full"
                  />
                </div>
              </div>
              <div
                class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex justify-end order-start"
              >
                <div class="flex items-center">
                  <vs-select
                    placeholder="Select Team"
                    class="selectExample"
                    autocomplete
                    v-model="SelectedTeam"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.name"
                      :text="item.name"
                      v-for="(item, index) in team_list"
                    />
                  </vs-select>
                </div>
              </div>
              <div
                class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex justify-end order-end"
              >
                <vs-select
                  placeholder="Select Spoc"
                  class="selectExample"
                  autocomplete
                  v-model="teamuserdata"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.id"
                    :text="item.first_name + ' ' + item.last_name"
                    v-for="(item, index) in teamUsers"
                  />
                </vs-select>
              </div>

              <!-- <div class="vx-col sm:w-1/4 w-full flex justify-end">
              <vs-button
                class="rounded-r-none p-3 md:px-8 md:py-3"
                @click="calendarView = 'month'"
                >Month</vs-button
              >
              <vs-button
                class="rounded-l-none p-3 md:px-8 md:py-3"
                @click="calendarView = 'week'"
                >Week</vs-button
              >
            </div> -->
            </div>

            <div class="vx-row sm:flex hidden mt-4">
              <div class="vx-col w-1/2 flex justify-start">
                <!-- Labels -->
                <!-- <div
                class="flex flex-wrap sm:justify-start justify-start"
                style="padding: 5px; border: 1px solid #dad5d5;border-radius:5px"
              >
                <b> Week-Off </b>&nbsp; :&nbsp;&nbsp;&nbsp; <b>{{ weekoff }}</b>
              </div> -->
              </div>
              <div class="vx-col w-1/2 flex justify-end">
                <!-- Labels -->
                <div class="flex flex-wrap sm:justify-start justify-center">
                  <div
                    v-for="(label, index) in calendarLabels"
                    :key="index"
                    class="flex items-center mr-4 mb-2"
                  >
                    <div
                      class="h-3 w-3 inline-block rounded-full mr-2"
                      :class="'bg-' + label.color"
                    ></div>
                    <span>{{ label.text }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div slot="item"
        :value="i"
							:weekStartDate="weekStart"
							:top="getItemTop(i)">
          <h3>ting</h3>
        </div> -->
          <!-- <div slot="event" slot-scope="props" :key="props.event.id">
          <h3>custom</h3>
        </div> -->
        </calendar-view>
      </div>

      <!-- apply list -->

      <vs-popup title="Leave Request Form" :active.sync="apply_leave">
        <!-- <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <h3>{{userName}}  {{teamName}} team</h3>
            </vs-col>
        </vs-row><br> -->
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <h4>Select Dates</h4>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <date-range-picker
              ref="picker"
              :opens="'center'"
              :locale-data="{
                firstDay: 1,
                format: 'DD-MMM-YYYY',
                customRangeLabel: 'Custom Range',
              }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="dateRange"
              style="width: 240px; margin-right: 3%"
              @update="clear"
            ></date-range-picker>
          </vs-col> </vs-row
        ><br />
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-textarea
              width="100%"
              style="height: 112px;"
              v-model="leaveReason"
              placeholder="Leave Reason"
            />
          </vs-col>
        </vs-row>
        <vs-row style="justify-content: center;">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button size="small" @click="applyLeaveRequest()"
              >Apply</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </div>
    <vs-popup
      class="holamundo"
      :title="`Change attendance for ${new Date(SelectedAttendanceData.startDate * 1000).toLocaleDateString('in')}`"
      :active.sync="attendancePopup"
    >
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="full_day"
            >1 Day</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="half_day"
            >1/2 Day</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="week_off"
            >W-Off</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="no_week_off"
            >No W-Off</vs-radio
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="absent"
            >Absent</vs-radio
          > </vs-col
        >      
      </vs-row>
        
      <vs-row style="margin-top:5%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="start" vs-w="6">
            <vs-radio
              v-model="attendanceRadio"
              vs-name="attendanceRadio"
              vs-value="remove_comp_off"
              >Remove Comp-Off</vs-radio
            > </vs-col
          >
          <vs-col v-if="this.ReportingData === true" vs-type="flex" vs-justify="flex-start" vs-align="start" vs-w="6">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="insert_record"
            >Add Insert Record</vs-radio
          > </vs-col
        >
      </vs-row><br />
      <vs-row style="margin-top:5%" v-if="this.attendanceRadio === 'full_day' || this.attendanceRadio === 'half_day' || this.attendanceRadio === 'week_off' || this.attendanceRadio === 'no_week_off' || this.attendanceRadio === 'absent' || this.attendanceRadio === 'remove_comp_off'">
        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="start" vs-w="6">
        </vs-col><br /> -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="start" vs-w="12">
          <vs-textarea v-model="comments" />
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="start" vs-w="6">
        </vs-col><br /> -->
      </vs-row><br />
      <vs-row v-if="this.attendanceRadio === 'insert_record'">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <eventInserter
            :user_id="this.userid"
            :disabled="disabled"
            @insertEvent="insertEvent"
            ></eventInserter>
          </vs-col
        ><br />
      </vs-row><br />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="primary"
            size="small"
            @click="ChangeAttendance()"
            type="filled"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup
      class="holamundo"
      :title="`Change attendance for ${new Date(SelectedAttendanceData.startDate * 1000).toLocaleDateString('in')}`"
      :active.sync="newattendancePopup"
    >
      <vs-row style="margin-top:5%">
        <vs-col v-if="this.ReportingData === true" vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-radio
            v-model="attendanceRadio"
            vs-name="attendanceRadio"
            vs-value="insert_record"
            >Add Insert Record</vs-radio
          > </vs-col
        ><br />
      </vs-row><br />

      <vs-row v-if="this.attendanceRadio === 'insert_record'">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <eventInserter
            :user_id="this.userid"
            :disabled="disabled"
            @insertEvent="insertEvent"
            ></eventInserter>
          </vs-col
        ><br />
      </vs-row><br />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="primary"
            size="small"
            @click="NewChangeAttendance()"
            type="filled"
            >Submit</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");
import router from "@/router";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
import eventInserter from "../components/attendance/MyTeamNewInsert.vue"

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    DateRangePicker,
    Datepicker,
    "v-select": vSelect,
    eventInserter,
  },
  data() {
    return {
      comments: "",
      newattendancePopup: false,
      disabled: false,
      AllSpocs: [],
      customHtml: "<div><h3>Custom</h3></div>",
      apply_leave: false,
      dateRange: {
        // startDate: this.getMonday(new Date()),
        startDate: new Date(),
        endDate: new Date(),
      },
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: "",
      startDate: "",
      endDate: "",
      labelLocal: "none",
      attendanceRadio: "",
      ReportingData: "",
      url: "",
      calendarView: "month",

      activePromptAddEvent: false,
      activePromptEditEvent: false,
      simpleCalendarEvents: [
        {
          id: null,
          title: "",
          startDate: null,
          classes: "",
          label: "",
        },
      ],
      team_id: "",
      teamName: "",
    //   userid: "",
      userName: "",
      leaveReason: "",
      team_list: [],
      spocAttendanceDate: [
        {
          id: 1,
          date: "05-08-2020",
          status: "absent",
        },
        {
          id: 2,
          date: "04-08-2020",
          status: "present",
        },
        {
          id: 3,
          date: "04-22-2020",
          status: "came_late",
        },
        {
          id: 4,
          date: "05-05-2020",
          status: "present",
        },
        {
          id: 5,
          date: "05-06-2020",
          status: "absent",
        },
        {
          id: 6,
          date: "05-07-2020",
          status: "left_early",
        },
        {
          id: 7,
          date: "05-19-2020",
          status: "present",
        },
        {
          id: 8,
          date: "05-21-2020",
          status: "left_early",
        },
        {
          id: 8,
          date: "05-20-2020",
          status: "came_late",
        },
      ],
      weekoff: "",
      select2: "",
      SelectedTeam: "",
      teamUsers: [],
      teamuserdata: "",
      selectedUserData: {
        userId: null,
        TeamName: "",
      },
      attendancePopup: false,
      SelectedAttendanceData: {
        userId: null,
        TeamName: "",
        title: "",
        classes: [],
        startDate: null,
      },

       itsCM4: false,
      loaded: false,
      today_date: {
        startDate: new Date(),
        endDate: new Date(),
      },
      selected_city: "",
      cities: [],
      all_visit_engagments: [],
      utilization_data: [],
      spocs: [],
      userid: "",
      firstDay: "",
      lastDay: "",
      all_call_logs: [],
      visit_engagments: [],
      training: [],
      masterMerge: [],
      user_id: "",
      NewObj: {}
    };
  },
  mounted() {
      let todaydate = new Date();
    this.firstDay = new Date(todaydate.getFullYear(), todaydate.getMonth(), 1);
    this.lastDay = new Date(
      todaydate.getFullYear(),
      todaydate.getMonth() + 1,
      0
    );
    console.log(
      "date ",
      this.datatoTimestamp(this.firstDay) +
        " - " +
        this.datatoTimestamp(this.lastDay)
    );
    this.start_date = this.datatoTimestamp(this.firstDay);
    this.end_date = this.datatoTimestamp(this.lastDay);

    this.today_date.startDate = this.firstDay;
    this.today_date.endDate = this.lastDay;

    this.getTeams();
    
    // this.myprofile();
    // this.attendanceshow();
  },
  watch: {
    attendanceRadio(value) {
      this.SelectedAttendanceData.title = value;
      this.comments = "";
      console.log("title", this.SelectedAttendanceData.title);
      // if(value === "insert_record") {
      //   this.myprofile()
      // }
    },
    SelectedTeam(value) {
      this.teamUsers = [];
      this.teamuserdata = "";
      console.log("selected team name", value);
      this.getAllUsers(value);
      this.SelectedAttendanceData.TeamName = value;
    },
    teamuserdata(value) {
      console.log(value);
    
      this.SelectedAttendanceData.userId = value;
      this.userid = value;
        // this.attendanceshow();
        if (value != "") {
          this.myprofile();
        this.reportGenerateAPI();
      }
      //  console.log("selected user id",value)
    },
  },
  computed: {
    disabledDatesTo() {
      return { to: new Date(this.startDate) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.endDate) };
    },
    calendarLabels() {
      return this.$store.state.calendar.calendarLabels;
    },
    labelColor() {
      return (label) => {
        if (label == "present") return "success";
        else if (label == "came_late") return "warning";
        else if (label == "left_early") return "warning";
        else if (label == "absent") return "danger";
        // if (label == "none") return "primary"
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    onClickDay(event) {
      console.log("dummy", event)
      this.attendanceRadio = "";
      console.log("event", event);
      let today = new Date();
      let today_date = moment(today).format('DD-MM-YYYY');
      let event_date = moment(event).format('DD-MM-YYYY');
      if(this.ReportingData === true) {
        if(event_date < today_date){
          this.newattendancePopup = true;
        }
      }
      
      this.SelectedAttendanceData.startDate = this.datatoTimestamp(
        event
      );
    },
    openEditEvent(event) {
      this.attendanceRadio = "";
      console.log("event", event);
      this.attendancePopup = true;
      this.SelectedAttendanceData.startDate = this.datatoTimestamp(
        event.startDate
      );
    },
    ChangeAttendance() {
      if (this.comments === "" && this.attendanceRadio !== "insert_record") {
        this.showCustomNotification(
          "Please mention your comments for changing attendance status",
          "Please mention your comments for changing attendance status",
          "danger"
        );
        return;
      } 
      if(this.attendanceRadio === "insert_record") {
        this.NewInsertRecord(this.NewObj);
      } else if (this.attendanceRadio !== "") {
        let obj = {
          spoc_id: this.userid,
          attendance_status: this.SelectedAttendanceData.title,
          attendance_date: this.SelectedAttendanceData.startDate,
          comments: this.comments
        };
        console.log("SelectedAttendanceData", obj);
        let url = `${constants.MILES_CM_BACK}changeAttendanceStatusForADay`;
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("change attendance responce", response);
            this.attendanceshow(this.masterMerge);
            if (response.data.status === "error") {
              this.$vs.notify({
                title: "",
                text: response.data.message,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                title: "",
                text: response.data.message,
                color: "success",
              });
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
        this.attendancePopup = false;
      } else {
        this.$vs.notify({
          title: "Do Not Leave Empty",
          text: "Please Mark Attendance",
          color: "danger",
        });
      }
    },
    NewChangeAttendance() {
      if(this.attendanceRadio === "insert_record") {
        this.NewInsertRecord(this.NewObj);
      } 
    },
    insertEvent(newEvent) {
      
      newEvent.timestamp = this.getTimestampFromTime(newEvent.time);
      console.log("attendence data", newEvent.timestamp)
      this.NewObj = newEvent;
      
    },
    NewInsertRecord(obj) {
      const url = `${constants.MILES_CM_BACK}insertWorkStatusEvent`;
      
      this.disabled = true;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response);
            // Replace current user's data with data from response
            this.newattendancePopup = false;
            this.attendancePopup = false;
            this.attendanceshow(this.masterMerge);
            this.$vs.notify({
              title: "Record Insertion Complete",
              text: `Inserted work status record successfully`,
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Duplicate record",
              text: response.data.message,
              color: "warning",
            });
            this.disabled = false;
          }
        })
        .catch((error) => {
          this.disabled = false;
          this.handleError(error);
        });
    },
    getTimestampFromTime(timeString) {
      
      const start = this.SelectedAttendanceData.startDate;
      console.log("this.startDate", timeString, start)
      const timeArray = timeString.split(":");
      const seconds_form_hours = timeArray[0] * 60 * 60;
      const seconds_from_minutes = timeArray[1] * 60;
      return start + seconds_form_hours + seconds_from_minutes;
    },
    myprofile() {
      let obj = {
        spoc_id: this.userid
      }
      let url = `${constants.MILES_CM_BACK}checkIfReportingManagerAbleToEditSpocAttendance`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("My profile", response.data);
          this.ReportingData = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    attendanceshow(masterMerge) {
      this.$vs.loading();
      this.spocAttendanceDate = [];
      this.simpleCalendarEvents = [];
      let url = `${constants.SERVER_API}getAttendanceAndCallLogs`;
      let obj = {
        user_id: this.userid,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("Hr data", response);
          if (response.data.status !== "error") {
            console.log("Hr data", response.data.data);

            let hrdata = response.data.data;
            hrdata.forEach((element) => {
              // let my_attendance = element.find(hr => hr.user_id === this.userid);
              // if(element.user_id === this.userid){

              this.spocAttendanceDate.push({
                id: element.id,
                date: moment.unix(element.attendance_date).format("YYYY-MM-DD"),
                // status: this.getDayStatus(element),
                status: element.status,
                call_utilization: element.call_utilization,
                call_count: element.call_count,
                login_time: element.login_time
                  ? moment.unix(element.login_time).format("HH:mm")
                  : "",
                logout_time: element.logout_time
                  ? moment.unix(element.logout_time).format("HH:mm")
                  : "",
                hours_logged: element.hours_logged
                  ? Math.round(parseFloat(element.hours_logged) * 100) / 100
                  : "",
                week_off: element.week_off,
                holiday: element.holiday,
                comp_off: element.comp_off,
              });
              // }
            });
            if (masterMerge.length !== 0 || masterMerge.length !== undefined) {
              this.spocAttendanceDate.forEach((ele) => {
                let dateconv = moment(ele.date).format("DD-MMM-YYYY");
                masterMerge.forEach((item) => {
                  if (item.date === dateconv) {
                    ele.utilization = item.total_utilization;
                  }
                  if (item.call_utilization === undefined) {
                    ele.utilization = '0%'
                  }
                 
                });
                 if(ele.utilization === undefined){
                     ele.utilization = '0%'
                  }
              });
            }
            
            // let mmDate = moment(mm.date)._d;

            this.spocAttendanceDate.forEach((element) => {
                        if (element.status === "present") {
                            if (
                                (element.week_off !== 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off == 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off !== 1 && element.holiday == 1)
                            ) {
                                this.simpleCalendarEvents.push({
                                    id: element.id,
                                    title: `Present - <br>${element.call_utilization}% - ${element.call_count} <br> ${element.login_time} - ${element.logout_time} <br> HOURS: ${element.hours_logged}`,
                                    startDate: new Date(element.date),
                                    classes: this.getDayClass(element),
                                    label: element.status,
                                    loginTime: element.login_time,
                                    logOutTime: element.logout_time,
                                    hoursLogged: element.hours_logged,
                                });
                            }
                        } else if (element.status === "came_late") {
                            if (
                                (element.week_off !== 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off == 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off !== 1 && element.holiday == 1)
                            ) {
                                this.simpleCalendarEvents.push({
                                    id: element.id,
                                    title: `Came Late - <br>${element.call_utilization}% - ${element.call_count} <br> ${element.login_time} - ${element.logout_time} <br> HOURS: ${element.hours_logged}`,
                                    startDate: new Date(element.date),
                                    classes: this.getDayClass(element),
                                    label: element.status,
                                    loginTime: element.login_time,
                                    logOutTime: element.logout_time,
                                    hoursLogged: element.hours_logged,
                                });
                            }
                        } else if (element.status === "left_early") {
                            if (
                                (element.week_off !== 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off == 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off !== 1 && element.holiday == 1)
                            ) {
                                this.simpleCalendarEvents.push({
                                    id: element.id,
                                    title: `Left Early - <br>${element.call_utilization}% - ${element.call_count}  <br> ${element.login_time} - ${element.logout_time} <br> HOURS: ${element.hours_logged}`,
                                    startDate: new Date(element.date),
                                    classes: this.getDayClass(element),
                                    label: element.status,
                                    loginTime: element.login_time,
                                    logOutTime: element.logout_time,
                                    hoursLogged: element.hours_logged,
                                });
                            }
                        } else if (
                            element.status === "absent" &&
                            element.week_off !== 1 &&
                            element.holiday !== 1
                        ) {
                            if (
                                (element.week_off !== 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off == 1 &&
                                    element.holiday !== 1) ||
                                (element.week_off !== 1 && element.holiday == 1)
                            ) {
                                // let absent_title_text = "Absent";
                                 
                                // if (element.hours_logged > 0.1) {
                                 let  absent_title_text = `Absent - <br>${element.call_utilization}% - ${element.call_count} <br> ${element.login_time} - ${element.logout_time} <br> HOURS: ${element.hours_logged}`;
                                // }
                                this.simpleCalendarEvents.push({
                                    id: element.id,
                                    title: absent_title_text,
                                    startDate: new Date(element.date),
                                    classes: this.getDayClass(element),
                                    label: element.status,
                                    loginTime: element.login_time,
                                    logOutTime: element.logout_time,
                                    hoursLogged: element.hours_logged,
                                });
                            }
                        }
                        if (element.holiday == 1) {
                            // let title_text = "Holiday";
                            // if (element.hours_logged > 1) {
                              let title_text = `Holiday- <br>${element.call_utilization}% - ${element.call_count} <br> ${element.login_time} - ${element.logout_time} <br> HOURS: ${element.hours_logged}`;
                            // }
                            this.simpleCalendarEvents.push({
                                id: element.id + "-holiday",
                                title: title_text,
                                startDate: new Date(element.date),
                                classes: "event-primary",
                                label: "Holiday",
                                // loginTime: element.login_time,
                                // logOutTime: element.logout_time,
                                // hoursLogged: element.hours_logged,
                            });
                        }

                        if (element.week_off == 1) {
                            if (
                                element.week_off == 1 &&
                                element.status == "absent" &&
                                element.hours_logged > 1
                            ) {
                                this.simpleCalendarEvents.push({
                                    id: element.id + "-weekoff",
                                    title: `Week Off - <br>${element.call_utilization}% - ${element.call_count} <br> ${element.login_time} - ${element.logout_time} <br> HOURS: ${element.hours_logged}`,
                                    startDate: new Date(element.date),
                                    classes: "event-primary",
                                    label: "Week Off",
                                });
                            } else {
                                this.simpleCalendarEvents.push({
                                    id: element.id + "-weekoff",
                                    title: `Week Off`,
                                    startDate: new Date(element.date),
                                    classes: "event-primary",
                                    label: "Week Off",
                                });
                            }
                        }

                        /* if (element.comp_off == 1) {
              this.simpleCalendarEvents.push({
                id: element.id,
                title: "COMP OFF",
                startDate: new Date(element.date),
                classes: "event-primary",
                label: "COMP OFF",
              });
            } */
                    });
            console.log(" this.spocAttendanceDate", this.spocAttendanceDate);
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "",
              text: response.data.message,
              color: "danger",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getDayClass(record){
      console.log("record",record)

      if (record.status === "absent") {
        return 'event-danger';
      } 
      if (record.hours_logged === 6 && record.status === "present") {
        return 'event-warning';
      }
      return 'event-success';
    },
    fetchweekOff(userid) {
      let url = `${constants.MILES_CM_BACK}getWeekOff?user_id=${userid}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.generateWeekoffs(response.data.data);
          // console.log("Hr data", response.data.data);
          // let hrdata = response.data.data;
          // hrdata.forEach((element) => {
          //   spocAttendanceDate.push({
          //     id: element.id,
          //     date: moment.unix(element.holiday_date).format("YYYY-MM-DD"),
          //     status: element.name,
          //   });
          // });

          // console.log(spocAttendanceDate);

          // spocAttendanceDate.forEach((element) => {
          //   this.simpleCalendarEvents.push({
          //     id: element.id,
          //     title: element.status,
          //     startDate: new Date(element.date),
          //     classes: "event-primary",
          //     label: element.status,
          //   });
          // });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    generateWeekoffs(data) {
      console.log(data);
      let weekoffs = data.week_off.split(",");
      console.log(weekoffs);
      weekoffs.forEach((day) => {
        this.addDatesToCalenderForDay(day);
      });
    },
    addDatesToCalenderForDay(provided_day) {
      var day = moment()
        .startOf("year")
        .day(provided_day);
      if (day.date() > 7) day.add(7, "d");
      var month = 0;
      while (month <= 52) {
        // console.log(day);
        this.simpleCalendarEvents.push({
          id: Math.random()
            .toString(16)
            .slice(2),
          title: "Week Off",
          startDate: new Date(day),
          classes: "event-primary",
          label: "weekOff",
        });
        day.add(7, "d");
        month++;
      }
    },
    fetchHolidays() {
      // this.spocAttendanceDate = [];
      // this.simpleCalendarEvents = [];
      let spocAttendanceDate = [];
      // let simpleCalendarEvents = []
      let url = `${constants.MILES_CM_BACK}holiday`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("Hr data", response.data.data);
          let hrdata = response.data.data;
          hrdata.forEach((element) => {
            spocAttendanceDate.push({
              id: element.id,
              date: moment.unix(element.holiday_date).format("YYYY-MM-DD"),
              status: element.name,
            });
          });

          console.log(spocAttendanceDate);

          spocAttendanceDate.forEach((element) => {
            this.simpleCalendarEvents.push({
              id: element.id,
              title: element.status,
              startDate: new Date(element.date),
              classes: "event-primary",
              label: element.status,
            });
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);

      this.firstDay = new Date(
        this.showDate.getFullYear(),
        this.showDate.getMonth(),
        1
      );
      this.lastDay = new Date(
        this.showDate.getFullYear(),
        this.showDate.getMonth() + 1,
        0
      );
      console.log(
        "date ",
        this.datatoTimestamp(this.firstDay) +
          " - " +
          this.datatoTimestamp(this.lastDay)
      );
      this.start_date = this.datatoTimestamp(this.firstDay);
      this.end_date = this.datatoTimestamp(this.lastDay);
      if (this.userid !== "") {
        this.reportGenerateAPI();
      }
    },
    clearFields() {
      this.title = this.endDate = this.url = "";
      this.id = 0;
      this.labelLocal = "none";
    },

    getTeams() {
      let url = `${constants.SERVER_API}team`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("team list", response.data.data);
          this.team_list = response.data.data;
          // this.myprofile();
          // let obj = this.team_list.find(team => team.id === this.team_id);
          // console.log('team list',obj.name)
          // this.teamName = obj.name;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getAllUsers(teamName) {
      let url = `${constants.SERVER_API}getAllUsers?team=${teamName}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("team users", response.data.data);
          this.teamUsers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    applyLeave() {
      // let user_id = "";

      this.apply_leave = true;
    },
    applyLeaveRequest() {
      console.log("dateRange", this.dateRange);
      let leaveObj = {
        user_id: this.userid,
        team_id: 1,
        leave_from_date: moment(this.dateRange.startDate).format(),
        leave_to_date: moment(this.dateRange.endDate).format(),
        leave_reason: this.leaveReason,
        status: "pending",
      };

      let url = `${constants.MILES_CM_BACK}leave`;
      axios
        .post(url, leaveObj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("team list", response);
          this.$vs.notify({
            title: "Leave",
            text: "Successfully Applied",
            color: "success",
          });
          this.apply_leave = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    leaveList() {
      router.push("/MyLeaves");
    },
    
    clear() {
      this.durationVmodel = null;
    },
    getDayStatus(record) {
      const in_time_limit = record.attendance_date + 10.5 * 3600;
      const out_time_limit = record.attendance_date + 17.5 * 3600;
      if (record.login_time && record.login_time > in_time_limit) {
        return "came_late";
      }
      if (record.logout_time && record.logout_time < out_time_limit) {
        return "left_early";
      }
      return record.status;
    },

    spocFilterByValue(arrayOfObject, val) {
      var filteredSpocs = arrayOfObject.filter(function(v) {
        if (v.full_name.toLowerCase().indexOf(val) >= 0) {
          return true;
        } else false;
      });
      console.log(filteredSpocs);
      this.teamUsers = filteredSpocs;
    },
    //============================================

    getRole(providedRole) {
      if (localStorage.getItem("role") === providedRole) {
        return true;
      } else {
        return false;
      }
    },

    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    showDangerNotification(response) {
      this.$vs.notify({
        title: "Danger",
        text: response.data.message,
        color: "danger",
      });
    },
    showWarningNotification(response) {
      this.$vs.notify({
        title: "Warning",
        text: response.data.message + " in line " + response.data.line,
        color: "warning",
      });
    },
    showErrorNotification(error) {
      this.$vs.notify({
        title: "Danger",
        text: error.response,
        color: "danger",
      });
    },

    async reportGenerateAPI() {
      this.$vs.loading();
      this.loaded = false;
      let spocjoin = [];
      this.spocs = [];
      this.spocs.push({ userid: this.userid });
      if (this.getRole("cm4") || this.getRole("hr")) {
        this.spocs.forEach((spoc) => {
          // if (spoc.city === this.selected_city) {
          spocjoin.push(spoc.userid);
          // }
        });
      } else {
        spocjoin.push(this.userid);
      }

      // let params = {
      //   // spoc_id: spocjoin[i],
      //   from_date: this.timetoTimestamp(this.today_date.startDate),
      //   to_date: this.timetoTimestamp(this.today_date.endDate),
      // };
      let spocsCollection = [];
      console.log(spocjoin);
      for (let i = 0; i < spocjoin.length; i++) {
        let params = {
          spoc_id: spocjoin[i],
          from_date: this.timetoTimestamp(this.firstDay),
          to_date: this.timetoTimestamp(this.lastDay),
        };

        let response = await this.getUtilization(params);
        spocsCollection.push(response.data.data);
        console.log(response);
      }
      console.log(spocsCollection);
      this.calculateUtilization(spocsCollection);

      // params.spoc_ids = spocjoin.join();
      // axios
      //   .get(`${constants.ADMIN_SERVER_API}getAllCallLogsForUtilization`, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //     params: params,
      //   })
      //   .then((response) => {
      //     if (response.data.code == 200) {
      //       this.removeUnwantedUsers(response.data.data);
      //       this.calculateUtilization(response.data.data);
      //     } else if (response.data.code == 403) {
      //       this.$vs.loading.close();
      //       this.showDangerNotification(response);
      //     } else {
      //       this.$vs.loading.close();
      //       this.showWarningNotification(response);
      //     }
      //   })
      //   .catch((error) => {
      //     this.handleError(error);
      //   });
    },
    getUtilization(params) {
      let url = `${constants.ADMIN_SERVER_API}getSpocCallLogsForUtilization`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            this.handleError(error);
            reject();
          });
      });
      return promise;
    },
    
    removeUnwantedUsers(spocData) {
      let index_to_be_removed = [];
      spocData.forEach((spoc, index) => {
        // console.log(spoc.attendance.length);
        if (spoc.attendance.length === 0) {
          index_to_be_removed.push(index);
        }
      });

      // index_to_be_removed.forEach((ind) => {
      //   spocData.splice(ind, 1);
      // });

      // spocData.forEach

      if (index_to_be_removed.length > 0) {
        for (var i = spocData.length - 1; i >= 0; i--) {
          if (index_to_be_removed.indexOf(i) !== -1) {
            spocData.splice(i, 1);
          }
        }
      }
      // console.log(index_to_be_removed);
    },
    getPaymentStatus(enrollment) {
      let value = 0;
      if (
        enrollment.amount_paid >= enrollment.agreed_cost ||
        enrollment.amount_in_process >= enrollment.agreed_cost ||
        enrollment.amount_in_process + enrollment.amount_paid >=
          enrollment.agreed_cost
      ) {
        value = 2;
      } else {
        value = 1;
      }

      return value;
    },
    calculateUtilization(utilization_data) {
      let all_visit_engagments = [];
      for (var i = 0; i < utilization_data.length; i++) {
        // console.log(utilization_data[i]);
        var call_duration_sec = 0;
        var total_calls = 0;
        var all_call_logs = utilization_data[i].all_call_logs;
        var business_calls_array = [];
        var mwb_call_count = 0;
        var mwb_connected_call_count = 0;
        var ewb_connected_call_count = 0;
        var ewb_call_count = 0;
        var untracked_in_calls_count = 0;
        var untracked_in_calls_duration = 0;
        var untracked_out_calls_count = 0;
        var untracked_out_calls_duration = 0;
        var incoming_call_count = 0;
        var outgoing_call_count = 0;
        var missed_call_count = 0;
        var call_30_60 = 0;
        var call_gt_60 = 0;
        var enrollments = [];
        var total_color = "";
        var nuc_count = 0;
        var nuc_duration = 0;
        var training = utilization_data[i].attendance.filter(function(eachatt) {
          return eachatt.type === "training";
        });
        var training_utilization = this.getTrainingUtilization(
          training,
          utilization_data[i].attendance
        );
        var mwb_arr = [];
        var ewb_arr = [];
        var missed_calls_arr = [];
        var nuc_arr = [];
        var average = 0;
        var visitaverage = 0;
        let connected_calls = 0;
        let connected_calls_arr = [];
        let enrollments_without_pending_payments = [];

        utilization_data[i].actual_enrollments.forEach((enrollment) => {
          let payment_status = this.getPaymentStatus(enrollment);

          if (payment_status === 2) {
            enrollments_without_pending_payments.push(enrollment);
          }
        });
        // console.log(utilization_data[i]);
        if (i === 0) {
          // console.log(utilization_data[i].visit_engagements);
        }
        utilization_data[i].visit_engagements.forEach((visit_engagment) => {
          all_visit_engagments.push(visit_engagment);
        });
        if (
          this.timetoTimestamp(this.firstDay) !==
          this.timetoTimestamp(this.lastDay)
        ) {
          average = this.getCallAverage(
            all_call_logs,
            utilization_data[i].attendance
          );
          visitaverage = this.getVisitAverage(
            utilization_data[i].visit_engagements,
            utilization_data[i].attendance
          );
        }
        // if (utilization_data[i]["all_call_logs"][j].directory == "MISSED") {
        //       if (utilization_data[i]["all_call_logs"][j].updated === 0) {
        //       missed_call_count++;
        //       missed_calls_arr.push(utilization_data[i]["all_call_logs"][j]);
        //       // console.log(utilization_data[i]["all_call_logs"][j]);
        //       }
        //     }
        utilization_data[i]["visit_engagements"].forEach((visit_engagement) => {
          if (visit_engagement.visit_status === "Enrollment") {
            // console.log(visit_engagement)
            enrollments.push(visit_engagement);
          }
        });

        for (var j = 0; j < utilization_data[i]["all_call_logs"].length; j++) {
          utilization_data[i]["all_call_logs"][j].spoc_name =
            utilization_data[i].spoc_name;
          if (
            utilization_data[i]["all_call_logs"][j].contact_type == "B2C" ||
            utilization_data[i]["all_call_logs"][j].contact_type == "B2BIR" ||
            utilization_data[i]["all_call_logs"][j].contact_type == "B2BCR"
          ) {
            if (utilization_data[i]["all_call_logs"][j].updated == 1) {
              business_calls_array.push(
                utilization_data[i]["all_call_logs"][j]
              );
              call_duration_sec +=
                utilization_data[i]["all_call_logs"][j].call_duration;
              total_calls++;
              if (
                utilization_data[i]["all_call_logs"][j].directory === "INCOMING"
              ) {
                incoming_call_count++;
              } else if (
                utilization_data[i]["all_call_logs"][j].directory === "OUTGOING"
              ) {
                outgoing_call_count++;
              }
              // console.log(
              //   utilization_data[i]["all_call_logs"][j].call_duration
              // );
              if (utilization_data[i]["all_call_logs"][j].call_duration > 0) {
                connected_calls++;
                connected_calls_arr.push(
                  utilization_data[i]["all_call_logs"][j]
                );
              }
              if (utilization_data[i]["all_call_logs"][j].level == "M7") {
                ewb_call_count++;
                ewb_arr.push(utilization_data[i]["all_call_logs"][j]);
                if (utilization_data[i]["all_call_logs"][j].call_duration > 0) {
                  ewb_connected_call_count++;
                }
              } else {
                mwb_call_count++;
                mwb_arr.push(utilization_data[i]["all_call_logs"][j]);
                if (utilization_data[i]["all_call_logs"][j].call_duration > 0) {
                  mwb_connected_call_count++;
                }
              }
              if (
                utilization_data[i]["all_call_logs"][j].call_duration >= 1800 &&
                utilization_data[i]["all_call_logs"][j].call_duration < 3600
              ) {
                call_30_60++;
              } else if (
                utilization_data[i]["all_call_logs"][j].call_duration >= 3600
              ) {
                call_gt_60++;
              }
            }
            // if (utilization_data[i]["all_call_logs"][j].directory == "MISSED") {
            //   if (utilization_data[i]["all_call_logs"][j].updated === 0) {
            //   missed_call_count++;
            //   missed_calls_arr.push(utilization_data[i]["all_call_logs"][j]);
            //   // console.log(utilization_data[i]["all_call_logs"][j]);
            //   }
            // }
          } else if (
            utilization_data[i]["all_call_logs"][j].contact_type == "untracked"
          ) {
            if (
              utilization_data[i]["all_call_logs"][j]["directory"] == "INCOMING"
            ) {
              untracked_in_calls_count++;
              untracked_in_calls_duration =
                untracked_in_calls_duration +
                utilization_data[i]["all_call_logs"][j]["call_duration"];
            } else if (
              utilization_data[i]["all_call_logs"][j]["directory"] == "OUTGOING"
            ) {
              untracked_out_calls_count++;
              untracked_out_calls_duration =
                untracked_out_calls_duration +
                utilization_data[i]["all_call_logs"][j]["call_duration"];
            }
          } else {
            nuc_count++;
            nuc_arr.push(utilization_data[i]["all_call_logs"][j]);
            nuc_duration =
              nuc_duration +
              utilization_data[i]["all_call_logs"][j].call_duration;
          }
        }
        utilization_data[i].all_call_logs.forEach((call) => {
          if (call.directory === "MISSED") {
            if (
              call.contact_type === "untracked" ||
              call.contact_type === "B2C"
            ) {
              if (call.updated === 0) {
                missed_call_count++;
                missed_calls_arr.push(call);
              }
            }
          }
        });

        var unique_calls_array = [
          ...new Set(business_calls_array.map((item) => item.person_id)),
        ];
        var call_duration_mins = Math.round(call_duration_sec / 60);
        var call_utilization = Math.round((call_duration_mins * 100) / 180);

        var visitor_utilization = 0;
        if (utilization_data[i]["visit_engagements"].length > 0) {
          var first_visit_count = 0;
          var enrollment_visit_count = 0;
          var repeat_visit_count = 0;
          for (
            var k = 0;
            k < utilization_data[i]["visit_engagements"].length;
            k++
          ) {
            if (
              utilization_data[i]["visit_engagements"][k]["visit_status"] ===
              "First"
            ) {
              //12.5%
              first_visit_count++;
            } else if (
              utilization_data[i]["visit_engagements"][k]["visit_status"] ===
              "Enrollment"
            ) {
              //6.25%
              if (
                utilization_data[i]["visit_engagements"][k]["visit_status"] ===
                  "Enrollment" &&
                utilization_data[i]["visit_engagements"][k]["visit_count"] === 1
              ) {
                first_visit_count++;
              } else {
                enrollment_visit_count++;
              }
            } else if (
              utilization_data[i]["visit_engagements"][k]["visit_status"] ===
              "Repeat"
            ) {
              //3.125%
              repeat_visit_count++;
            }
          }
          visitor_utilization =
            first_visit_count * 12.5 +
            enrollment_visit_count * 6.25 +
            repeat_visit_count * 3.125;
        }

        utilization_data[i].incoming_call_count = incoming_call_count;
        utilization_data[i].outgoing_call_count = outgoing_call_count;
        utilization_data[i].enrollments = enrollments;
        utilization_data[i].call_duration_sec = call_duration_sec;
        utilization_data[i].training = training;
        utilization_data[i].call_utilization = call_utilization + "%";
        utilization_data[i].visitor_utilization = visitor_utilization + "%";
        utilization_data[i].training_utilization = training_utilization + "%";
        utilization_data[i].total_utilization =
          Math.round(call_utilization + visitor_utilization) + "%";
        if (call_utilization + visitor_utilization >= 100) {
          total_color = "success";
        } else if (
          call_utilization + visitor_utilization >= 80 &&
          call_utilization + visitor_utilization < 100
        ) {
          total_color = "warning";
        } else if (call_utilization + visitor_utilization < 80) {
          total_color = "danger";
        }
        if (
          this.timetoTimestamp(this.firstDay) !==
          this.timetoTimestamp(this.lastDay)
        ) {
          // console.log(average);
          utilization_data[i].call_utilization = average + "%";
          utilization_data[i].visitor_utilization = visitaverage + "%";
          utilization_data[i].total_utilization =
            Math.round(average + visitaverage) + "%";
          if (average + visitaverage >= 100) {
            total_color = "success";
          } else if (
            average + visitaverage >= 80 &&
            average + visitaverage < 100
          ) {
            total_color = "warning";
          } else if (average + visitaverage < 80) {
            total_color = "danger";
          }
        }
        utilization_data[i].total_calls = total_calls;
        utilization_data[i].total_unique_calls = unique_calls_array.length;
        utilization_data[i].total_duration = this.secondsToHms(
          call_duration_sec
        );
        utilization_data[i].total_color = total_color;
        utilization_data[i].mwb_connected_call_count = mwb_connected_call_count;
        utilization_data[i].ewb_connected_call_count = ewb_connected_call_count;
        utilization_data[
          i
        ].enrollments_without_pending_payments = enrollments_without_pending_payments;
        utilization_data[i].mwb_call_count = mwb_call_count;
        utilization_data[i].connected_calls = connected_calls;
        utilization_data[i].connected_calls_arr = connected_calls_arr;
        utilization_data[i].mwb_arr = mwb_arr;
        utilization_data[i].ewb_call_count = ewb_call_count;
        utilization_data[i].ewb_arr = ewb_arr;
        utilization_data[i].untracked_in_calls_count = untracked_in_calls_count;
        utilization_data[i].untracked_in_calls_duration = this.secondsToHms(
          untracked_in_calls_duration
        );
        utilization_data[
          i
        ].untracked_out_calls_count = untracked_out_calls_count;
        utilization_data[i].untracked_out_calls_duration = this.secondsToHms(
          untracked_out_calls_duration
        );
        utilization_data[i].missed_call_count = missed_call_count;
        utilization_data[i].missed_calls_arr = missed_calls_arr;
        utilization_data[i].call_30_60 = call_30_60;
        utilization_data[i].call_gt_60 = call_gt_60;
        utilization_data[i].nuc_count = nuc_count;
        utilization_data[i].nuc_arr = nuc_arr;
        utilization_data[i].nuc_duration = this.secondsToHms(nuc_duration);
      }
      utilization_data.forEach((spoc) => {
        spoc.visit_count = this.getMyVisitCount(
          spoc.spoc_id,
          all_visit_engagments
        );
      });
      this.utilization_data = utilization_data;
      console.log(utilization_data);
      if (this.utilization_data !== "") {
        this.getUtilizationByDate();
      }
      this.loaded = true;
      this.$vs.loading.close();
    },
    giveAttendanceTitle(item) {
      // console.log(item.attendance);
      if (item.attendance.length > 0) {
        return this.formattedAttendance(item.attendance[0].type);
      } else {
        return "";
      }
    },
    formattedAttendance(word) {
      let replced = word.replace(/_/g, " ");
      let formatted = replced
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return formatted;
    },
    getMyVisitCount(id, visitArray) {
      let visits_by_id = [];
      visitArray.forEach((visit_engagement) => {
        if (visit_engagement.assigned_spoc_id === id) {
          visits_by_id.push(visit_engagement);
        }
      });
      return visits_by_id.length;
    },
    calculateAverage(arrpercentage, attendance) {
      let add = 0;
      // let present = attendance.filter(function(att) {
      //   return att.type === "present";
      // });
      let present = [];

      attendance.forEach((day) => {
        day.formatted_date = moment(day.created_at).format("YYYY MM DD");
        // console.log(day.formatted_date);
        if (
          present.indexOf(day.formatted_date) === -1 &&
          day.type === "present"
        ) {
          present.push(day.formatted_date);
        }
      });

      arrpercentage.forEach((arr) => {
        add = add + arr;
      });
      // console.log(present);
      let ave = Math.round(add / present.length);
      if (!isFinite(ave)) {
        return "NA";
      } else {
        return ave;
      }
      // return Math.round(add / present.length);
    },
    getCallAverage(callLogs, attendance) {
      // console.log("called for average");
      let arrpercentage = this.getPercentageByDay(callLogs);
      let average = this.calculateAverage(arrpercentage, attendance);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getPercentageByDay(callLogs) {
      let time = [];
      let ByDayData = [];
      callLogs.forEach((log) => {
        log.updated_at_date = moment.unix(log.time / 1000).format("DD-MM-YYYY");
      });
      var unique = callLogs
        .map((name) => {
          return {
            count: 1,
            name: name.updated_at_date,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        callLogs.forEach((log) => {
          if (log.updated_at_date === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      // console.log(`USER ID => ${callLogs[0].user_id}`);
      // console.log(unique);
      // console.log(ByDayData);
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getPercentage(dayData.data);
        percentage.push(value);
      });
      // console.log(percentage);
      return percentage;
    },
    getPercentage(callLogs) {
      let percentage = 0;
      let seconds = 0;
      callLogs.forEach((log) => {
        if (
          log.contact_type === "B2C" ||
          log.contact_type === "B2BIR" ||
          log.contact_type === "B2BCR"
        ) {
          seconds = seconds + log.call_duration;
        }
      });
      let minutes = Math.round(seconds / 60);
      percentage = Math.round((minutes * 100) / 180);
      return percentage;
    },
    getVisitAverage(engagements, attendance) {
      // console.log(engagements);
      let arr = this.getVisitpercentageperday(engagements);
      let average = this.calculateAverage(arr, attendance);
      // console.log(average);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getVisitpercentageperday(engagements) {
      let time = [];
      let ByDayData = [];
      engagements.forEach((piece) => {
        piece.date_for_calc = piece.created_at.split(" ")[0];
      });
      var unique = engagements
        .map((name) => {
          return {
            count: 1,
            name: name.date_for_calc,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        engagements.forEach((log) => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      // console.log(unique);
      // console.log(ByDayData);
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getvisitPercentage(dayData.data);
        percentage.push(value);
      });
      // console.log(percentage);
      return percentage;
    },
    getvisitPercentage(daydata) {
      let percentage = 0;
      daydata.forEach((day) => {
        if (day.visit_status === "First") {
          percentage = percentage + 12.5;
        } else if (day.visit_status === "Enrollment") {
          percentage = percentage + 6.25;
        } else if (day.visit_status === "Repeat") {
          percentage = percentage + 3.125;
        }
      });
      return percentage;
    },
    getTrainingUtilization(TrainingData, attendance) {
      if (
        this.timetoTimestamp(this.firstDay) !==
        this.timetoTimestamp(this.lastDay)
      ) {
        let utilization = this.getTrainingAverage(TrainingData, attendance);
        if (isNaN(utilization) !== true) {
          return utilization;
        } else {
          return 0;
        }
      } else {
        if (TrainingData.length !== 0) {
          let utilization = this.getTrainingPercentage(TrainingData);
          return utilization;
        } else {
          return 0;
        }
      }
    },
    getTrainingAverage(TrainingData, attendance) {
      let getByday = this.getTrainingDayAverage(TrainingData);
      let average = this.calculateAverage(getByday, attendance);
      return average;
    },
    getTrainingDayAverage(TrainingData) {
      let time = [];
      let ByDayData = [];
      TrainingData.forEach((piece) => {
        piece.date_for_calc = piece.created_at.split(" ")[0];
      });
      var unique = TrainingData.map((name) => {
        return {
          count: 1,
          name: name.date_for_calc,
        };
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count;
        return a;
      }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        TrainingData.forEach((log) => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getTrainingPercentage(dayData.data);
        percentage.push(value);
      });
      return percentage;
    },
    getTrainingPercentage(data) {
      let minutes = 0;
      data.forEach((datapoint) => {
        minutes = minutes + datapoint.minutes;
      });
      let percentage = (minutes / 60) * 12.5;
      return Math.round(percentage);
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    getselectedspoc(obj) {
      router.push({ name: "CallLogs", params: { selected_obj: obj } });
    },
    getUtilizationByDate() {
      this.all_call_logs = this.utilization_data[0].all_call_logs;
      // this.visit_engagments = this.users.visit_engagements;
      this.visit_engagments = this.utilization_data[0].actual_enrollments;
      this.training = this.utilization_data[0].training;
      this.sortCallLogsByDay();
      console.log("masterMerge", this.masterMerge);
    },
    sortCallLogsByDay() {
      this.all_call_logs.forEach((data) => {
        data.sortDate = this.convertTimestampToDate(data.time);
      });
      this.visit_engagments.forEach((data) => {
        data.sortDate = data.created_at.split(" ")[0];
        data.sortDate = moment(data.sortDate, "YYYY-MM-DD").format(
          "DD-MMM-YYYY"
        );
        // console.log(data.sortDate);
      });
      this.training.forEach((data) => {
        data.sortDate = data.created_at.split(" ")[0];
      });
      // console.log(this.all_call_logs);
      this.createDayObjects();
    },
    createDayObjects() {
      // console.log(this.users);
      let call_obj = [];
      let visit_obj = [];
      let training_obj = [];
      var unique = this.all_call_logs
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          let itemObj = {
            date: key,
            data: [],
          };
          call_obj.push(itemObj);
        }
      }
      call_obj.forEach((dateObj) => {
        this.all_call_logs.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });

      var unique2 = this.visit_engagments
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key2 in unique2) {
        if (unique2.hasOwnProperty(key2)) {
          let itemObj = {
            date: key2,
            data: [],
          };
          visit_obj.push(itemObj);
        }
      }

      visit_obj.forEach((dateObj) => {
        this.visit_engagments.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });

      var unique3 = this.training
        .map((name) => {
          return {
            count: 1,
            name: name.sortDate,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key3 in unique3) {
        if (unique3.hasOwnProperty(key3)) {
          let itemObj = {
            date: key3,
            data: [],
          };
          training_obj.push(itemObj);
        }
      }
      training_obj.forEach((dateObj) => {
        this.training.forEach((data) => {
          if (data.sortDate === dateObj.date) {
            dateObj.data.push(data);
          }
        });
      });
      // console.log(call_obj);
      // console.log(visit_obj);
      // console.log(training_obj);
      this.mergeAllSections(call_obj, visit_obj, training_obj);
      // this.calculateUtilization(obj);
    },
    mergeAllSections(call_obj, visit_obj, training_obj) {
      this.masterMerge = [];
      call_obj.forEach((call) => {
        let obj = {
          date: call.date,
        };
        this.masterMerge.push(obj);
      });

      visit_obj.forEach((visit) => {
        let obj = {
          date: visit.date,
        };
        if (this.findIndexinMaster(obj) === -1) {
          this.masterMerge.push(obj);
        }
      });

      training_obj.forEach((training) => {
        let obj = {
          date: training.date,
        };
        if (this.findIndexinMaster(obj) === -1) {
          this.masterMerge.push(obj);
        }
      });

      this.masterMerge.forEach((master) => {
        master.all_call_logs = [];
        master.visit_engagements = [];
        master.training = [];
      });

      call_obj.forEach((call) => {
        this.insertRespectiveFields(call, "call");
      });

      visit_obj.forEach((visit) => {
        this.insertRespectiveFields(visit, "visit");
      });

      training_obj.forEach((train) => {
        this.insertRespectiveFields(train, "training");
      });

      // console.log(this.masterMerge);
      this.Utilizationcalculate(this.masterMerge);
    },
    Utilizationcalculate(all_call_logs) {
      for (var i = 0; i < all_call_logs.length; i++) {
        var call_duration_sec = 0;
        var total_calls = 0;
        // var all_call_logs = all_call_logs[i].all_call_logs;
        var business_calls_array = [];
        var mwb_call_count = 0;
        var ewb_call_count = 0;
        var untracked_in_calls_count = 0;
        var untracked_in_calls_duration = 0;
        var untracked_out_calls_count = 0;
        var untracked_out_calls_duration = 0;
        var missed_call_count = 0;
        var call_30_60 = 0;
        var call_gt_60 = 0;
        var total_color = "";
        var nuc_count = 0;
        var nuc_duration = 0;
        var training_utilization = 0;
        var mwb_arr = [];
        var ewb_arr = [];
        var missed_calls_arr = [];
        var nuc_arr = [];
        var enrollments = [];
        let connected_calls = 0;
        let connected_calls_arr = [];
        let incoming_call_count = 0;
        let outgoing_call_count = 0;
        // var average = 0;
        // var visitaverage = 0;
        // console.log("passed");
        // console.log(all_call_logs[i]["all_call_logs"]);

        // all_call_logs[i]["visit_engagements"].forEach((visit_engagement) => {
        //   if (visit_engagement.visit_status === "Enrollment") {
        //     enrollments.push(visit_engagement);
        //   }
        // });
        // console.log(all_call_logs[i]["visit_engagements"]);
        enrollments = all_call_logs[i]["visit_engagements"];

        for (var j = 0; j < all_call_logs[i]["all_call_logs"].length; j++) {
          all_call_logs[i]["all_call_logs"][j].spoc_name =
            all_call_logs[i].spoc_name;
          if (
            all_call_logs[i]["all_call_logs"][j].contact_type == "B2C" ||
            all_call_logs[i]["all_call_logs"][j].contact_type == "B2BIR" ||
            all_call_logs[i]["all_call_logs"][j].contact_type == "B2BCR"
          ) {
            if (all_call_logs[i]["all_call_logs"][j].updated == 1) {
              business_calls_array.push(all_call_logs[i]["all_call_logs"][j]);
              call_duration_sec +=
                all_call_logs[i]["all_call_logs"][j].call_duration;
              total_calls++;
              if (
                all_call_logs[i]["all_call_logs"][j].directory === "INCOMING"
              ) {
                incoming_call_count++;
              } else if (
                all_call_logs[i]["all_call_logs"][j].directory === "OUTGOING"
              ) {
                outgoing_call_count++;
              }
              if (all_call_logs[i]["all_call_logs"][j].call_duration > 0) {
                connected_calls++;
                connected_calls_arr.push(all_call_logs[i]["all_call_logs"][j]);
              }
              if (all_call_logs[i]["all_call_logs"][j].level == "M7") {
                ewb_call_count++;
                ewb_arr.push(all_call_logs[i]["all_call_logs"][j]);
              } else {
                mwb_call_count++;
                mwb_arr.push(all_call_logs[i]["all_call_logs"][j]);
              }
              if (
                all_call_logs[i]["all_call_logs"][j].call_duration >= 1800 &&
                all_call_logs[i]["all_call_logs"][j].call_duration < 3600
              ) {
                call_30_60++;
              } else if (
                all_call_logs[i]["all_call_logs"][j].call_duration >= 3600
              ) {
                call_gt_60++;
              }
            }
            // if (all_call_logs[i]["all_call_logs"][j].directory == "MISSED") {
            //   if (
            //     all_call_logs[i]["all_call_logs"][j].contact_type ===
            //       "untracked" ||
            //     all_call_logs[i]["all_call_logs"][j].contact_type === "B2C"
            //   ) {
            //     if (all_call_logs[i]["all_call_logs"][j].updated === 0) {
            //       missed_call_count++;
            //       missed_calls_arr.push(call);
            //     }
            //   }
            //   missed_call_count++;
            //   missed_calls_arr.push(all_call_logs[i]["all_call_logs"][j]);
            // }
          } else if (
            all_call_logs[i]["all_call_logs"][j].contact_type == "untracked"
          ) {
            if (
              all_call_logs[i]["all_call_logs"][j]["directory"] == "INCOMING"
            ) {
              untracked_in_calls_count++;
              untracked_in_calls_duration =
                untracked_in_calls_duration +
                all_call_logs[i]["all_call_logs"][j]["call_duration"];
            } else if (
              all_call_logs[i]["all_call_logs"][j]["directory"] == "OUTGOING"
            ) {
              untracked_out_calls_count++;
              untracked_out_calls_duration =
                untracked_out_calls_duration +
                all_call_logs[i]["all_call_logs"][j]["call_duration"];
            }
          } else {
            nuc_count++;
            nuc_arr.push(all_call_logs[i]["all_call_logs"][j]);
            nuc_duration =
              nuc_duration + all_call_logs[i]["all_call_logs"][j].call_duration;
          }
        }
        all_call_logs[i].all_call_logs.forEach((call) => {
          if (call.directory === "MISSED") {
            if (
              call.contact_type === "untracked" ||
              call.contact_type === "B2C"
            ) {
              if (call.updated === 0) {
                missed_call_count++;
                missed_calls_arr.push(call);
              }
            }
          }
        });
        var unique_calls_array = [
          ...new Set(business_calls_array.map((item) => item.person_id)),
        ];
        var call_duration_mins = Math.round(call_duration_sec / 60);
        var call_utilization = Math.round((call_duration_mins * 100) / 180);
        // console.log("generated utilization");
        var visitor_utilization = 0;
        // console.log("passed");
        if (all_call_logs[i]["visit_engagements"].length > 0) {
          var first_visit_count = 0;
          var enrollment_visit_count = 0;
          var repeat_visit_count = 0;
          for (
            var k = 0;
            k < all_call_logs[i]["visit_engagements"].length;
            k++
          ) {
            if (
              all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
              "First"
            ) {
              //12.5%
              first_visit_count++;
            } else if (
              all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
              "Enrollment"
            ) {
              if (
                all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
                  "Enrollment" &&
                all_call_logs[i]["visit_engagements"][k]["visit_count"] === 1
              ) {
                first_visit_count++;
              } else {
                enrollment_visit_count++;
              }
              //6.25%
              // enrollment_visit_count++;
            } else if (
              all_call_logs[i]["visit_engagements"][k]["visit_status"] ===
              "Repeat"
            ) {
              //3.125%
              repeat_visit_count++;
            }
          }
          visitor_utilization =
            first_visit_count * 12.5 +
            enrollment_visit_count * 6.25 +
            repeat_visit_count * 3.125;
        }

        training_utilization = this.getTrainingPercentage(
          all_call_logs[i]["training"]
        );

        all_call_logs[i].call_duration_sec = call_duration_sec;
        all_call_logs[i].call_utilization = call_utilization + "%";
        all_call_logs[i].visitor_utilization = visitor_utilization + "%";
        all_call_logs[i].training_utilization = training_utilization + "%";
        all_call_logs[i].total_utilization =
          Math.round(call_utilization + visitor_utilization) + "%";
        if (call_utilization + visitor_utilization >= 100) {
          total_color = "success";
        } else if (
          call_utilization + visitor_utilization >= 80 &&
          call_utilization + visitor_utilization < 100
        ) {
          total_color = "warning";
        } else if (call_utilization + visitor_utilization < 80) {
          total_color = "danger";
        }
        all_call_logs[i].total_calls = total_calls;
        all_call_logs[i].enrollments = enrollments;
        all_call_logs[i].incoming_call_count = incoming_call_count;
        all_call_logs[i].outgoing_call_count = outgoing_call_count;
        all_call_logs[i].total_color = total_color;
        all_call_logs[i].total_unique_calls = unique_calls_array.length;
        all_call_logs[i].total_duration = this.secondsToHms(call_duration_sec);
        all_call_logs[i].mwb_call_count = mwb_call_count;
        all_call_logs[i].connected_calls = connected_calls;
        all_call_logs[i].connected_calls_arr = connected_calls_arr;
        all_call_logs[i].mwb_arr = mwb_arr;
        all_call_logs[i].ewb_call_count = ewb_call_count;
        all_call_logs[i].ewb_arr = ewb_arr;
        all_call_logs[i].untracked_in_calls_count = untracked_in_calls_count;
        all_call_logs[i].untracked_in_calls_duration = this.secondsToHms(
          untracked_in_calls_duration
        );
        all_call_logs[i].untracked_out_calls_count = untracked_out_calls_count;
        all_call_logs[i].untracked_out_calls_duration = this.secondsToHms(
          untracked_out_calls_duration
        );
        all_call_logs[i].missed_call_count = missed_call_count;
        all_call_logs[i].missed_calls_arr = missed_calls_arr;
        all_call_logs[i].call_30_60 = call_30_60;
        all_call_logs[i].call_gt_60 = call_gt_60;
        all_call_logs[i].nuc_count = nuc_count;
        all_call_logs[i].nuc_arr = nuc_arr;
        all_call_logs[i].nuc_duration = this.secondsToHms(nuc_duration);
      }
      this.all_call_logs = all_call_logs;
      this.attendanceshow(this.masterMerge);
      // console.log("calculated utilization data is ", this.all_call_logs);
      this.$vs.loading.close();
    },
    insertRespectiveFields(obj, type) {
      this.masterMerge.forEach((master) => {
        if (master.date === obj.date) {
          if (type === "call") {
            master.all_call_logs = obj.data;
          } else if (type === "visit") {
            master.visit_engagements = obj.data;
          } else if (type === "training") {
            master.training = obj.data;
          }
        }
      });
    },
    findIndexinMaster(obj) {
      let returnindex = -1;
      this.masterMerge.forEach((master, index) => {
        if (master.date === obj.date) {
          returnindex = index;
        }
      });
      return returnindex;
    },
    convertTimestampToDate(timestamp) {
      let date = moment.unix(timestamp / 1000).format("DD-MMM-YYYY");
      return date;
    },
    timeConvert(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },

  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/simple-calendar.scss";
.cv-event {
  justify-content: center;
  display: flex;
}
.app-fixed-height {
  height: calc(100vh - 8.1rem);
}
// .con-vs-popup .vs-popup {
//   width: 900px;
// }
// .daterangepicker.openscenter[data-v-6e7dbc50]{
//   position: fixed;
//   left: 900px;
//   top: 310px;
// }
.drp-calendar.col.right {
  padding: 0px;
}
.daterangepicker.openscenter[data-v-8cc9549e] {
  position: fixed;
}
.vs-con-table {
  width: 100%;
}
.vs-textarea {
  height: 110px;
}
.form-control.reportrange-text {
  border-radius: 5px;
}
</style>
